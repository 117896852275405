var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `shepherd-app-${_vm.appData.aid}` },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "offset-x": "",
            "open-on-hover": "",
            "close-delay": _vm.closingDelay,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      {
                        attrs: {
                          to: {
                            name: "app-open",
                            params: {
                              oid: _vm.$route.params.oid,
                              sid: _vm.$route.params.sid,
                              iid: _vm.$route.params.iid,
                              snid: _vm.$route.params.snid,
                              aid: _vm.appData.aid,
                              sessionId: _vm.appData.session_id,
                            },
                            query: _vm.$route.query,
                          },
                        },
                      },
                      on
                    ),
                    [
                      _vm.appData.app_type !== undefined
                        ? _c("v-list-item-icon", [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _vm.appData.mount_handback_to_files_for_hid !==
                                  "None" ||
                                _vm.appData.mount_handback_for_bid !== "None"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                          "z-index": "1000",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mt-n4 mr-n4",
                                            staticStyle: {
                                              "background-color":
                                                "var(--v-primary-base)",
                                            },
                                          },
                                          [
                                            _vm.appData
                                              .mount_handback_to_files_for_hid !==
                                            "None"
                                              ? _c(
                                                  "v-icon",
                                                  { attrs: { dark: "" } },
                                                  [_vm._v("grading")]
                                                )
                                              : _vm._e(),
                                            _vm.appData
                                              .mount_handback_for_bid !== "None"
                                              ? _c(
                                                  "v-icon",
                                                  { attrs: { dark: "" } },
                                                  [_vm._v("visibility")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _vm.appData.gpu
                                      ? _c(
                                          "v-badge",
                                          {
                                            attrs: {
                                              color: "light-green",
                                              overlap: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "badge",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "nudgeUp",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-memory mdi-rotate-45"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                width: "35",
                                                height: "35",
                                                src: _vm.appIcon(_vm.appData),
                                              },
                                            }),
                                          ]
                                        )
                                      : _c("img", {
                                          attrs: {
                                            width: "35",
                                            height: "35",
                                            src: _vm.appIcon(_vm.appData),
                                          },
                                        }),
                                    _c(
                                      "div",
                                      { staticClass: "mx-n3 mt-1 mb-n2" },
                                      [
                                        _c("TheSideBarResourceInfo", {
                                          attrs: {
                                            currentApp: _vm.getAppInfo(
                                              _vm.appData.aid
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("v-list-item-title", [_vm._v("Applications")]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column w-100" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c(
                              "span",
                              { staticClass: "caption text-uppercase mr-2" },
                              [_vm._v(_vm._s(_vm.appData.name))]
                            ),
                            _vm.appData.shared === "true"
                              ? _c(
                                  "v-chip",
                                  { attrs: { small: "", outlined: "" } },
                                  [_vm._v("shared")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.appData.gpu
                          ? _c("span", { staticClass: "caption" }, [
                              _vm._v("on " + _vm._s(_vm.appData.gpu) + " GPU"),
                            ])
                          : _vm._e(),
                        _c("v-divider", {
                          staticClass: "mt-1",
                          attrs: { color: "grey" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between",
                      },
                      [
                        _vm._v(" Memory limit: "),
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              { staticClass: "font-weight-medium ml-1" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getAppInfo(_vm.appData.aid).limit_memory
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                color: "secondary",
                                                dark: "",
                                                small: "",
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v("info")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "If this is breached, the app is restarted. There can be some delay in reporting."
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-group",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        to: {
                          name: "app-open",
                          params: {
                            oid: _vm.$route.params.oid,
                            sid: _vm.$route.params.sid,
                            iid: _vm.$route.params.iid,
                            snid: _vm.$route.params.snid,
                            aid: _vm.appData.aid,
                            sessionId: _vm.appData.session_id,
                          },
                          query: _vm.$route.query,
                        },
                        "active-class": "hide-active-state",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("launch")]
                      ),
                      _vm._v(" Open in new tab "),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.showNewMessageIntercom(
                            "Hi Nuvolos Team, \nI would like to ask your help regarding the following:\n",
                            _vm.userInfo
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("help")]
                      ),
                      _vm._v(" Ask for help / report an issue "),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "snapshot-applications",
                            query: { resetAid: _vm.appData.aid },
                          })
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("auto_fix_high")]
                      ),
                      _vm._v(" Clear user application settings "),
                    ],
                    1
                  ),
                  _c(
                    "StopApplication",
                    {
                      attrs: {
                        appName: _vm.appData.name,
                        appId: _vm.appData.aid.toString(),
                        isShared:
                          _vm.getAppInfo(_vm.appData.aid).shared === "true",
                        sessionId: _vm.appData.session_id,
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("stop")]
                          ),
                          _vm._v(" Stop "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }